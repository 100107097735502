import useFetch from '../useFetch';
import { useEffect, useState, useContext } from 'react';
import PageHeader from '../AppComponents/SmallComponents/PageHeader';
import SectionHeader from '../AppComponents/SmallComponents/SectionHeader';
import { Context } from '../Store';
import emailjs from '@emailjs/browser';
import { Skeleton } from '@mui/material';

const SCOPE = "https://www.googleapis.com/auth/calendar";

const Book = () => {
    // booking data
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [location, setLocation] = useState('');
    const [availableTimes, setAvailableTimes] = useState([]);

    // global user data
    const { userData, token } = useContext(Context);
    const [user] = userData;
    const [tokenClient, setTokenClient] = token;

    const [data_user, setData_user] = useState({});
    const [isDetailsPending, setIsDetailsPenidng] = useState(true);
    const [detailError, setDetailError] = useState(false);
    const [tooManyReq, setTooManyReq] = useState(false);
    const [bookingTaken, setBookingTaken] = useState(false);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [CLIENT_ID, setCLIENT_ID] = useState("");

    const [serviceID, setServiceID] = useState("");
    const [templateID, setTempalteID] = useState("");
    const [publicKey, setPublickey] = useState("");

    // fetch availability data
    const { data, isPending, error, setData } = useFetch(process.env.REACT_APP_API_URL + '/book', []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])  

    useEffect(() => {
        // once data is fetched, update the available time slots
        if (data && data.length > 0) {
            setStartDate(data[data.length - 1].date);
            setEndDate(data[0].date);
        }
    }, [data]);

    // setting available times based on the date drop down
    useEffect(() => {
        if (data && data.length > 0) {
            const bookingDateInfo = data.filter((bookingInfo) => {
                return bookingInfo.date === date;
            })
            try {
                if (bookingDateInfo.length > 0) {
                    setAvailableTimes(bookingDateInfo[0].times.sort());
                }
            } catch (err) {
                console.log(err);
            }
            
        }

    }, [date, data]);

    // fetch user booking information if available
    // find out why this is called twice
    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            fetch(process.env.REACT_APP_API_URL + '/book/' + user.email)
            .then(res => {
                if(!res.ok) {
                    throw Error('Could not fetch the data for that resource');
                }
                return res.json();
                })
            .then(result => {
                setData_user(result);
            }).catch(err => console.log(err.message));
        }
    }, [user])

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/google-sign-in")
        .then(result => result.json())
        .then(result => setCLIENT_ID(result.clientID))
        .catch(err => console.log(err));

        fetch(process.env.REACT_APP_API_URL + "/email-key")
        .then(result => result.json())
        .then(result => {
            setServiceID(result.serviceID);
            setTempalteID(result.templateID);
            setPublickey(result.publickey);
        })
    }, []);


    useEffect(() => {
        // access token
        /* global google */
        if (CLIENT_ID.length > 0) {
            const google = window.google;
            setTokenClient(
                google.accounts.oauth2.initTokenClient({
                client_id: CLIENT_ID,
                scope: SCOPE,
                callback: (tokenResponse) => {
                    if (tokenResponse && tokenResponse.access_token) {
                        processAddToCalendar(tokenResponse.access_token);
                    }
                }
            }));
        }
    }, [data_user, CLIENT_ID]); // add a state condition to run this again dates are set properly

    const processAddToCalendar = (token) => {
        // init start and end times for the hair cut
        const offset = '-04:00'; // for now or -05:00 for daylight savings
        const start = new Date(`${data_user.date}T${data_user.time}${offset}`);
        const end = new Date(`${data_user.date}T${addHourToMilitaryTime(data_user.time)}${offset}`);

        const event = {
            'summary': 'Jaylolfadez Haircut',
            'description': 'Scheduled hair cut appointment',
            'start' :{
                'dateTime': start.toISOString(),
                'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            'end' :{
                'dateTime': end.toISOString(),
                'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        }
        
        // create fetch post request
        fetch('https://www.googleapis.com/calendar/v3/calendars/primary/events',{
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(event)
            }
        ).then((data) => {
            return data.json();
        }).then((response) => {
            alert("Added to Calendar!");
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const handleBook = (e) => {
        e.preventDefault();
        const userEmail = user.email;
        const userSub = user.sub;
        const bookInfo = {email: userEmail, date: date, time: time, sub: userSub, location: location};

        const book = JSON.stringify(bookInfo);
        
        // creat the user booking
        fetch(process.env.REACT_APP_API_URL + '/book/create', {
            method: 'POST',  
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'},
            body: book,
            mode: 'cors',
            cache: 'default'})
            .then(result => {
                if (result.status === 429) {
                    return result.json().then(errorData => {
                        setTooManyReq(true);
                        throw new Error(errorData.message);
                    })
                }
                if (result.status === 400) {
                    setBookingTaken(true);
                }
                if(!result.ok) {
                    return result.json().then(errorData => {
                        throw new Error(errorData.message);
                    })
                }
                return result.json()})
            .then((result) => {
                sendEmail()
                setBookingTaken(false);
                setTooManyReq(false);
                setData_user(result);
                setDate("");
                setTime("");
                update();
            }).catch(err => {
                console.log(err.message || 'an error has occured while fetching data');
            });
        

        // update the time availability
        const update = () => {
            // pull this time from the array instead fitlering, will create more errors
            putNewAvailability({times : time}, "remove-time", date);
        }

        const sendEmail = () => {
            const templateParams = {
                name: user.name,
                time: time,
                date: date,
                location: location,
                email: user.email
            };
    
            emailjs.send(serviceID, templateID, templateParams, publicKey)
            .then((response) => {
                //console.log("email sent");
            })
            .catch(err => {
                console.log(err);
            })
        }
    }

    const putNewAvailability = (time, method, date) => {
        fetch(process.env.REACT_APP_API_URL + `/book/${method}/${date}`, {
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'},
            body: JSON.stringify(time),
            mode: 'cors',
            cache: 'default'
        }).then(result => result.json())
        .then((result) => {
            setData(result);
        }).catch(err => {
            console.log(err);
        })
    }

    const handleDelete = () => {
        // code to delete a booking with id
        fetch(process.env.REACT_APP_API_URL + '/book/' + data_user._id, {method: 'DELETE'})
        .then(result => {
            setData_user({});
            update();
        })
        .catch(err =>{ 
            console.log(err);
        });
        

        const update = () => {
            const bookingDateInfo = data.filter((bookingInfo) => {
                return bookingInfo.date === data_user.date;
            })
            
            // taking original full array and not the most recent updated one after updating
            const newTimeArray = bookingDateInfo[0].times;

            if (!newTimeArray.includes(data_user.time)) {
                newTimeArray.push(data_user.time);
            }
            newTimeArray.sort();
    
            //console.log(newTimeArray);
            putNewAvailability({times : data_user.time}, "add-time", data_user.date);
        }
    }

    function addCalendar() {
        tokenClient.requestAccessToken();
    }

    function tConvert (time) {
        const [hourString, minute] = time.split(":");
        const hour = +hourString % 24;
        return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
    }
    
    function addHourToMilitaryTime(militaryTime) {
        // Split the military time into hours and minutes
        let [hours, minutes, seconds] = militaryTime.split(':').map(Number);
        
        // Add one hour
        hours = (hours + 1) % 24; // Modulus 24 to wrap around if it goes beyond 23
        
        // Format the hours and minutes back into a string
        const newHours = String(hours).padStart(2, '0');
        const newMinutes = String(minutes).padStart(2, '0');
        const newSeconds = String(seconds).padStart(2, '0');
        const newMilitaryTime = `${newHours}:${newMinutes}:${newSeconds}`;
        
        return newMilitaryTime;
    }

    return ( 
        <div>
            <PageHeader title="Book Now"/>
            {isPending && 
            <div>
                <Skeleton variant="rounded w-auto book-container" height={600}/>
            </div>
            }
            {error && <p className="gray-text">Could not fetch time availabilities and booking information</p>}
            {data &&
            <div className="book-container add-shadow flex justify-evenly">
                <form className="book-form flex flex-col justify-center p-10" onSubmit={handleBook}>
                    <h4 className="text-2xl">Bookings</h4>
                    <label>Date:</label>
                    <input type="date" id="date" name="date" 
                    min={startDate} max={endDate}
                    value={date} onChange={(e) => setDate(e.target.value)} required/>


                    <label>Time:</label>
                    <select value={time} onChange={(e) => setTime(e.target.value)} required>
                        <option value="">Select a Time</option>
                        {
                            availableTimes.length > 0 && availableTimes.map((time, i) => (
                                <option value={time} key={i}>{tConvert(time)}</option>
                            ))

                        }
                    </select>

                    <label>Location:</label>
                    <select value={location} onChange={(e) => setLocation(e.target.value)} required>
                        <option value="">Select a Location</option>
                        <option value="Standard">Standard</option>
                        <option value="Mobile">Mobile (+$5)</option>
                    </select>


                    {// if user is logged in and no bookings
                    Object.keys(user).length !== 0 && Object.keys(data_user).length === 0 && <button>Book</button>}
                    {// if user is not logged in or booking exists
                    (Object.keys(user).length === 0) && <button className="disabled" disabled>Book</button>}
                    {// if user is logged in and booking is done
                    Object.keys(user).length !== 0 && Object.keys(data_user).length !== 0 && <button className="disabled booking-found" disabled>Booking Complete!</button>}
                    {Object.keys(user).length === 0 && <p className="gray-text mt-5">Sign In to Book</p>}
                    {Object.keys(user).length !== 0 && <p className="gray-text mt-5">Booking will be done with: <b>{user.email}</b></p>}
                    {bookingTaken && <p className="gray-text mt-2">Booking time taken, please try a different time</p>}
                    {tooManyReq && <p className="gray-text mt-2">Too many requests, please try again later</p>}
                </form>
                
                <div className="your-bookings">
                    {
                    // if booking exists then display
                    Object.keys(data_user).length !== 0 && 
                    <div className="booking-details">
                        <h4 className="text-2xl mb-6">Booking Scheduled!</h4>
                        <table className="booking-table text-xl gray-text">
                            <tbody>
                                <tr>
                                    <td className="text-left text-black">Email: </td>
                                    <td className="text-xl gray-text text-right">{data_user.email}</td>
                                </tr>
                                <tr>
                                    <td className="text-left text-black">Date: </td>
                                    <td className="text-xl gray-text text-right">{data_user.date} at {tConvert(data_user.time)}</td>
                                </tr>
                                <tr>
                                    <td className="text-left text-black">Location: </td>
                                    <td className="text-xl gray-text text-right">{data_user.location}</td>
                                </tr>
                            </tbody>
                            
                        </table>

                        <button onClick={addCalendar} className="text-black mt-10 color1-text">Add to Google Calendar</button>
                        {/* <button className="text-black mt-10 gray-text" disabled>Google Calendar: Pending Verification from Google</button>  */}
                        <br></br>
                        <button onClick={handleDelete} className="delete-booking mt-2">Delete Booking</button>
                    </div>
                    }

                    {
                        // booking doesnt exist
                        Object.keys(data_user).length === 0 && 
                        <div className="p-20">
                            <h4 className="text-xl mb-3">No Haircuts Booked</h4>
                            <p className="text-xl gray-text">Please sign-in and book</p>
                        </div>
                    }

                </div>
            </div>}
            
        </div>
     );
}
 
export default Book;