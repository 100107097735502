import PageHeader from "../AppComponents/SmallComponents/PageHeader";
import ServiceTable from "../AppComponents/SmallComponents/ServiceTable";
import { useEffect } from 'react';

const Services = () => {
    const data = [
        ["Cut", "$20", "$30"],
        ["Eyebrom Trim", "$5", "$5"],
        ["Beard Trim/Lineup", "$5", "$5"],
    ]
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])  

    const maleImage = "https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post2/img1.png";
    const femaleImage = "";

    return ( 
        <div className="services">
            <PageHeader title="Services"/>
            {/* <ServiceTable data={data}/> */}
            <div className="service-grid">
                <div className="service-grid-item add-shadow">
                    <div className="service-grid-item-image" style={{backgroundImage : `url(${maleImage})`}}>
                        <div className="absolute service-grid-item-image-gradient"></div>
                        <div className="pl-10 pr-10">
                            <h3 className="absolute service-grid-item-header mb-3 text-3xl">Men's Services</h3>
                            <div className="absolute separator-service bottom-0 men"></div>
                        </div>         
                    </div>
                    <div className="service-grid-item-description p-10 h-full">
                        <h4>Available styling options for men:</h4>
                        <div className="flex justify-between mt-2">
                            <p>Standard Hair Cut</p>
                            <p>$20</p>       
                        </div>
                        <div className="flex justify-between mt-2">
                            <p>Eyebrow Trims</p>
                            <p>$5</p>       
                        </div>
                        <div className="flex justify-between mt-2">
                            <p>House Calls</p>
                            <p>$5</p>       
                        </div>
                    </div>
                </div>

                <div className="service-grid-item add-shadow">
                    <div className="service-grid-item-image" style={{backgroundImage : `url(${femaleImage})`}}>
                        <div className="absolute service-grid-item-image-gradient"></div>
                        <div className="pl-10 pr-10">
                            <h3 className="absolute service-grid-item-header mb-3 text-3xl">Women's Services</h3>
                            <div className="absolute separator-service bottom-0 women"></div>
                        </div>         
                    </div>
                    <div className="service-grid-item-description p-10 h-full">
                        <h4>Available styling options for women:</h4>
                        <div className="flex justify-between mt-2">
                            <p>Standard Hair Cut</p>
                            <p>$40</p>       
                        </div>
                        <div className="flex justify-between mt-2">
                            <p>Eyebrow Trims</p>
                            <p>$5</p>       
                        </div>
                        <div className="flex justify-between mt-2">
                            <p>House Calls</p>
                            <p>$5</p>       
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Services;