
const ImageGallery = () => {
    const gridImage1 = "https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post4/gridimg3.png";
    const gridImage2 = "https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post6/IMG_9641.png";
    const gridImage3 = "https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post1/IMG_9551.png";
    const gridImage4 = "https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post2/img1.png";
    const gridImage5 = "https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post3/gridimg2.png";
    const gridImage6 = "https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post5/IMG_2566.png";
    const gridImage7 = "https://jaylolfadez.s3.us-east-2.amazonaws.com/gallery/post7/IMG_9789.png";
    const gridImage8 = "";
    return ( 
        <div className="grid-gallery">
            <figure className="grid-gallery__item grid-gallery__item--1">
                <img src={gridImage1} className="grid-gallery__img" alt="cut 1" loading="lazy"></img>
            </figure>
            <figure className="grid-gallery__item grid-gallery__item--2">
                <img src={gridImage2} className="grid-gallery__img" alt="cut 2" loading="lazy"></img>
            </figure>
            <figure className="grid-gallery__item grid-gallery__item--3">
                <img src={gridImage1} className="grid-gallery__img" alt="cut 3" loading="lazy"></img>
            </figure>
            <figure className="grid-gallery__item grid-gallery__item--4">
                <img src={gridImage5} className="grid-gallery__img" alt="cut 4" loading="lazy"></img>
            </figure>
            <figure className="grid-gallery__item grid-gallery__item--5">
                <img src={gridImage7} className="grid-gallery__img" alt="cut 5" loading="lazy"></img>
            </figure>
            <figure className="grid-gallery__item grid-gallery__item--6">
                <img src={gridImage4} className="grid-gallery__img" alt="cut 6" loading="lazy"></img>
            </figure>
            <figure className="grid-gallery__item grid-gallery__item--7">
                <img src={gridImage3} className="grid-gallery__img" alt="cut 7" loading="lazy"></img>
            </figure>
            <figure className="grid-gallery__item grid-gallery__item--8">
                <img src={gridImage6} className="grid-gallery__img" alt="cut 8" loading="lazy"></img>
            </figure>
        </div>
     );
}
 
export default ImageGallery;